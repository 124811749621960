import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMessageDownloadFilesRequestPayload,
	IMessageRequestPayload,
	IMessageStore,
	IMessageWithPagination,
	IReadMessageRequestPayload,
} from './types';

export const initialState: IMessageStore = {
	messages: null,
	loading: false,
	counter: 0,
};

const messages = createSlice({
	name: '@@messages',
	initialState,
	reducers: {
		getMessagesRequest: (state, action: PayloadAction<IMessageRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		getMessagesSuccess: (state, action: PayloadAction<IMessageWithPagination>) => {
			const newState = state;
			newState.messages = action.payload;
			newState.loading = false;
		},

		downloadFilesSuccess: (state) => {
			const newState = state;
		},
		downloadFilesRequest: (state, action: PayloadAction<IMessageDownloadFilesRequestPayload>) => {
			const newState = state;
		},
		readMessageRequest: (state, action: PayloadAction<IReadMessageRequestPayload>) => {
			const newState = state;
			newState.loading = false;
		},
		getUnreadMessageRequest: (state, action: PayloadAction<IMessageRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		getUnreadMessageSuccess: (state, action: PayloadAction<IMessageWithPagination>) => {
			const newState = state;
			newState.counter = action.payload.data.filter((item) => !item.view).length;
		},
	},
});

export default messages.reducer;

export const {
	getMessagesRequest,
	getMessagesSuccess,
	downloadFilesRequest,
	downloadFilesSuccess,
	readMessageRequest,
	getUnreadMessageRequest,
	getUnreadMessageSuccess,
} = messages.actions;
