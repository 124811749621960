import React, { FC, useEffect, useState } from 'react';
import { IApiLedgerItem } from 'services/api/transactions/types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { useRouteMatch } from 'react-router';
import { ICurrencyDataItem } from 'redux/reducers/currency/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from 'assets/inline-svg';
import { shortNotation } from 'services/utils/strings';
import { Link } from 'react-router-dom';
import { notificationContainer } from 'services/utils/notificationContainer';
import { roundingNumber } from '../../../services/utils/roundingNumber';

export interface IStatementTableRow {
	data: IApiLedgerItem;
}

const TableBody: FC<IStatementTableRow> = ({ data }) => {
	const currency = useSelector(getCurrencyData);
	const [asset, setAsset] = useState<ICurrencyDataItem>();
	const { url } = useRouteMatch();

	useEffect(() => {
		setAsset(currency.filter((item) => item.code === data.currency)[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard', 'info');
	};

	const handleState = (type: string, id: number, userId: number) => {
		switch (type) {
			case 'trade':
				return {
					fromStatementTrade: true,
					fromStatementReports: true,

					txId: id,
					userId,
				};
			case 'deposit':
				if (asset?.type === 'crypto' || asset?.type === 'token') {
					return {
						fromStatementDepositCrypto: true,
						fromStatementReports: true,

						txId: id,
						userId,
					};
				}
				return {
					fromStatementDepositFiat: true,
					fromStatementReports: true,
					txId: id,
					userId,
				};
			case 'withdrawal':
				if (asset?.type === 'crypto' || asset?.type === 'token') {
					return {
						fromStatementWithdrawalCrypto: true,
						fromStatementReports: true,
						txId: id,
						userId,
					};
				}
				return {
					fromStatementWithdrawalFiat: true,
					fromStatementReports: true,
					txId: id,
					userId,
				};
			default:
				return {};
		}
	};

	return (
		<div className="tr">
			<div className="td td--nowrap">
				<div className="td-hidden-name">Timestamp</div>
				<div className="td__wrap">
					{!!data.completed_at && (
						<>
							<div className="td__text">{moment.utc(data.completed_at).format('YYYY-MM-DD')}</div>
							<div className="td__more">{moment.utc(data.completed_at).format('HH:mm:ss')}</div>
						</>
					)}
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Type</div>
				<div className="td__wrap">
					<div className="td__text">
						{data.type.charAt(0).toUpperCase()}
						{data.type.slice(1)}
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Currency</div>
				<div className="td__wrap">
					<div className="td__text">{data?.currency.toUpperCase()}</div>
					<div className="td__more">
						{currency?.filter((item) => item?.code === data?.currency)[0]?.name}
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Debit</div>
				<div className="td__wrap">
					<div className="td__text">
						{data.debit ? roundingNumber(data.debit, data.currency) : '-'}
					</div>
					{data.debit && <div className="td__more">{data?.currency.toUpperCase()}</div>}
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Credit</div>
				<div className="td__wrap">
					<div className="td__text">
						{data.credit ? roundingNumber(data.credit, data.currency) : '-'}
					</div>
					{data.credit && <div className="td__more">{data?.currency.toUpperCase()}</div>}
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Fee</div>
				<div className="td__wrap">
					<div className="td__text">
						{roundingNumber(data.fee, data.currency)}
						<div className="td__more">{data?.currency.toUpperCase()}</div>
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Credit Fee</div>
				<div className="td__wrap">
					<div className="td__text">
						{data.credit_fee ? (
							<>
								{roundingNumber(data.credit_fee, data.currency)}
								<div className="td__more">{data?.currency.toUpperCase()}</div>
							</>
						) : (
							'-'
						)}
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Debit Fee</div>
				<div className="td__wrap">
					<div className="td__text">
						{data.debit_fee ? (
							<>
								{roundingNumber(data.debit_fee, data.currency)}
								<div className="td__more">{data?.currency.toUpperCase()}</div>
							</>
						) : (
							'-'
						)}
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Net</div>
				<div className="td__wrap">
					<div className="td__text">
						{roundingNumber(data.net, data.currency)}
						<div className="td__more">{data?.currency.toUpperCase()}</div>
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Balance</div>
				<div className="td__wrap">
					<div className="td__text">{roundingNumber(data.balance, data.currency)}</div>
					<div className="td__more">{data?.currency.toUpperCase()}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Destination</div>
				<div className="td__wrap">
					{data.destination.length > 30 && asset?.type !== 'fiat' ? (
						<div className="copy-button">
							{data.destination ? shortNotation(data.destination, 4, 4) : '-'}
							{data.destination && (
								<CopyToClipboard text={data.destination} onCopy={handleCopy}>
									<button type="button">
										<CopyIcon />
									</button>
								</CopyToClipboard>
							)}
						</div>
					) : (
						<>
							<div className="td__text">{data.destination.split('|')[0]}</div>
							<div className="td__more">{data.destination.split('|')[1]}</div>
						</>
					)}
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Reference</div>
				<div className="td__wrap">
					{' '}
					<div className="td__text">
						{data.reference && (
							<div className="tooltip-item-note">
								<p className="tooltip-item-text">
									{data.reference.length > 10
										? shortNotation(data.reference, 4, 4)
										: data.reference}
								</p>
								{data.reference.length > 10 && (
									<div className="tooltip-note">
										<p>{data.reference}</p>
										<i />
									</div>
								)}
							</div>
						)}
						{!data.reference && data.ext_tx_hash && (
							<div className="tooltip-item-note">
								<p className="tooltip-item-text">
									{data.ext_tx_hash.length > 10
										? shortNotation(data.ext_tx_hash, 4, 4)
										: data.ext_tx_hash}
								</p>
								{data.ext_tx_hash.length > 10 && (
									<div className="tooltip-note">
										<p>{data.ext_tx_hash}</p>
										<i />
									</div>
								)}
							</div>
						)}
						{!data.reference && !data.ext_tx_hash && (
							<div className="tooltip-item-note">
								<p className="tooltip-item-text">-</p>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Tx ID</div>
				<div className="td__wrap">
					<div className="td__text">
						<Link
							to={{
								pathname: `${url}/${data.tx_id}`,
								state: handleState(data.type, data.tx_id, data.user_id),
							}}
						>
							{data.tx_id}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
export default TableBody;
